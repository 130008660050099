module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  SOCKET_URL: process.env.REACT_APP_API_CP_DOMAIN,
  BACKEND_CP_URL: process.env.REACT_APP_SOCKET_URL,
  MAX_UNAPPROVED_ACCOUNTS: 3,
  enableFX: process.env.REACT_APP_ENABLE_FX === "true" || true,
  enableCrypto: process.env.REACT_APP_ENABLE_CRYPTO === "true" || false,
  enableCryptoWallets: process.env.REACT_APP_ENABLE_CRYPTO_WALLETS === "true" || false,
  defaultPortal: process.env.REACT_APP_DEFAULT_PORTAL || "FOREX",
  companyCryptoDeposit: process.env.REACT_APP_COMPANY_CRYPTO_DEPOSIT === "true" || false,
  COMPANY_WALLETS: [
    {
      name:"USDT",
      networks: [
        {
          name: "TRC20",
          address: "TDvoQn4nYD7QeasdidiDbnidNoVMDwujht",
          tag: "TRC20",
        },
        {
          name: "ERC20",
          address: "0x79470880a13a9be6b2e43bf98f3f4e7501366437",
          tag: "ERC20",
        }
      ],
    },
    {
      name:"BTC",
      networks: [
        {
          name: "BTC",
          address: "37DgPSYNxFcY9W8hmBkKBDs9RJwMSu2Sy5",
          tag: "BTC",
        },
      ],
    },
    {
      name:"ETH",
      networks: [
        {
          name: "ERC20",
          address: "0x79470880a13a9be6b2e43bf98f3f4e7501366437",
          tag: "ERC20",
        },
      ],
    }
  ],
  ENABLE_DARK_MODE: true,
  ENABLE_GLOBAL_SEARCH: false,
  LEVERAGES: [1, 50, 100],
  PLATFORMS: [
    {
      name: "Desktop Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.metatrader5.com/i/main/metatrader-5-windows.jpg",
      isLocalImg: false,
      downloadLink: "https://download.mql5.com/cdn/web/xeone.prime.ltd/mt5/xeoneprime5setup.exe",
    },
    {
      name: "Android Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://i.ytimg.com/vi/MTLbJ5lSW9M/maxresdefault.jpg",
      isLocalImg: false,
      downloadLink: "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/metatrader5.apk?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes",
    },
    {
      name: "IOS Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.ifcmarkets.ae/images/front/platforms/MT4-MT5-mobile-ios.png",
      isLocalImg: false,
      downloadLink: "https://download.mql5.com/cdn/mobile/mt5/ios?server=XeOnePrime-Server",
      
    },
  ],
  INVESTOR_LINK: "https://pamm.xeone.com/app/auth/investor",
  MANAGER_LINK: "https://pamm.xeone.com/app/auth/manager"
};

import horizontal from "assets/images/brands/logo-sm.png";
import logo from "assets/images/brands/logo.png";

export const clientName = "XeOne";
export const developedBy = "XeOne";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "XeOne";
export const companyWebsite = "https://xeone.com/";
export const privacyPolicyLink = "https://xeone.s3.ap-south-1.amazonaws.com/docs/XeOne_privacy_policy.pdf";
export const clientAgreementLink = "https://xeone.s3.ap-south-1.amazonaws.com/docs/XeOne_client_agreement.pdf";
export const LPOAAgreementLink = "https://xeone.s3.ap-south-1.amazonaws.com/docs/XeOne_LPOA.pdf";
export const executionPolicy = "https://xeone.s3.ap-south-1.amazonaws.com/docs/XeOne_client_agreement.pdf";
export const conflictPolicy = "https://xeone.s3.ap-south-1.amazonaws.com/docs/XeOne_conflict_of_interest_policy.pdf";
export const disclosurePolicy = "https://xeone.s3.ap-south-1.amazonaws.com/docs/XeOne_terms_of_business.pdf";
export const sidebarLogo = horizontal;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;

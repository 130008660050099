import CardWrapper from "components/Common/CardWrapper";
import { lightLogo, mainLogo } from "content";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";

const ForexFooter = (props) => {
  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));
  return (
    <Container className="my-4">
      <CardWrapper className="px-5 py-4 shadow glass-card" style={{
        background: layoutMode === "dark" ? "#1e1e2f" : "#fff",
      }}>
        <p className="text-secondary">
          {props.t("XeOne Prime is authorised by the Financial Services Commission (FSC) in Mauritius with registration number and investment dealer licence number GB23201851. The registered office of XeOne Prime is at 3 Emerald Park, Trianon,Quatre Bornes, 72257, Republic of Mauritius.")}
        </p>
        <br />
        <p className="text-secondary">
          {props.t("Risk Warning - CFDs are complex instruments and come with a high risk of losing money rapidly. Investments under the same can result in both - profit and loss. Before undertaking any such transactions, you should ensure that you fully understand the risks involved and seek independent advice if necessary.")}
        </p>
        <hr className="my-4 text-secondary"/>
        <span>&copy; {props.t("Copyright")} {new Date().getFullYear()} <img src={
          layoutMode === "dark" ? mainLogo : lightLogo
        } alt="Finitic Logo" height="20"/>, {props.t("All rights reserved")}.</span>
      </CardWrapper>
    </Container>
  );
};

export default withTranslation()(ForexFooter);
/* eslint-disable object-curly-newline */
import { useTranslation, withTranslation } from "react-i18next";
import { Button, CardBody, CardFooter, CardTitle, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllProfilesAPI } from "apis/forex/mam_pam";
import { capitalize } from "lodash";
import CardWrapper from "components/Common/CardWrapper";
import { JClickHandler } from "components/Journey/handlers";
import { toggleCurrentModal } from "store/actions";
import LinkModal from "pages/Forex/Dashboard/InvestorPortal/MasterTraders/LinkModal";
import CardView from "./CardView";
import RowView from "./RowView";
import {
  Tbody, Th, Thead, Tr, Table
} from "react-super-responsive-table";
import ProfileBasicStats from "pages/Forex/Dashboard/InvestorPortal/MasterTraders/ProfileBasicStats";

const MasterTraders = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { clientData } = useSelector((state) => state.Profile);
  const { currentModal, modalData } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));
  const { portal, subPortal } = useSelector((state) => ({
    portal: state.Layout.portal,
    subPortal: state.Layout.subPortal,
  }));
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [masterTraders, setMasterTraders] = useState([]);
  const [viewByCard, setViewByCard] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAllProfilesAPI({
      params: {
        page: 1,
        limit: 1000,
      }
    }).then((res) => {
      if (res.result) {
        const newMasterTraders = res.result?.docs?.map((item) => ({
          _id: item._id,
          name: `${capitalize(item.customerId?.firstName)} ${capitalize(item.customerId?.lastName)}`,
          investors: item.slaves?.length,
          profileId: item._id,
          equity: item.masterTradingAccountId?.Equity ?? 0,
          login: item.masterTradingAccountId?.login,
          ...item,
        }));
        setMasterTraders(newMasterTraders);
      } else {
        setMasterTraders([]);
      }
    }).catch((err) => {
      console.log(err);
      setMasterTraders([]);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const getProfileName = (profileType) => {
    switch (profileType) {
      // case 1 : return " MAM";
      case 2 : return " PAM";
      case 9: return " Copy Trading/Social Trading";
      default : return "";
    }
  };
  
  console.log("Master Traders", masterTraders);
  return (
    <Container className="mt-5 forex-dashboard">
      <div className="page-content">
      </div>
      <h1 className="color-primary">{t("Leaderboard")}</h1>
      <Row className="mt-2">
        {loading ? (
          <div className="text-center w-100">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (<>
          {masterTraders?.sort((a, b) => b.masterTradingAccountId?.totalProfit - a.masterTradingAccountId?.totalProfit)
            .sort((a, b) => b.investors - a.investors)
            // need to show only 8
            .slice(0, masterTraders?.length > 4 ? 4 : masterTraders?.length)
            .map((masterTrader, key) => (
              <Col key={key} md={3} className="my-2">
                <CardWrapper className="accounts-tab shadow glass-card">
                  <CardTitle className="text-center">
                    <ProfileBasicStats profile={masterTrader} />
                  </CardTitle>
                  <CardBody className="my-3 text-center">
                    <div className="d-flex justify-content-between">
                      <h5>{t("Username")}: </h5>
                      <h5 className="color-primary">{masterTrader.username ?? 0}</h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h5>{t("Equity")}: </h5>
                      <h5 className="color-primary">{masterTrader.equity ?? 0}</h5>
                    </div>
                    <div className="d-flex justify-content-between my-2">
                      <h5>{t("Investors")}: </h5>
                      <h5 className="color-primary">{masterTrader.investors ?? 0}</h5>
                    </div>
                    <div className="d-flex justify-content-between my-1">
                      <h5>{t("Profile Type")}: </h5>
                      <h5 className="color-primary">{getProfileName(masterTrader.profileType)}</h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h5>{t("Total Profit Made")}: </h5>
                      <h5 className="color-primary">{masterTrader.masterTradingAccountId?.totalProfit ?? 0}</h5>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button className="btn btn-primary color-bg-btn border-0" color="primary w-100" onClick={()=>{
                      setProfile(masterTrader);
                      JClickHandler("Invest", {
                        ...clientData.stages,
                        loaded: true,
                      }, dispatch, toggleCurrentModal, subPortal, portal)();
                    }}>
                      {t("Invest")}
                    </Button>
                  </CardFooter>
                </CardWrapper>
              </Col>
            ))}
        </>)}
      </Row>
      {!loading && masterTraders?.length !== 0 && <Row >
        {/* <h1 className="color-primary">{t("All Master Traders")}</h1> */}
        {/* A header which can change style of master trader cards either by row or by card view */}
        {/* <Row className="my-3">
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary color-bg-btn border-0 mx-2"
              color="primary"
              onClick={() => {
                setViewByCard(false);
              }}
            >
              <i
                className={"bx bx-list-ul bx-sm"}
              ></i>
            </Button>
            <Button
              className="btn btn-primary color-bg-btn border-0 mx-1"
              color="primary"
              onClick={() => {
                setViewByCard(true);
              }}
            >
              <i
                className={"bx bx-grid-alt bx-sm"}
              ></i>
            </Button>
          </div>
        </Row> */}
        {!loading && masterTraders?.length !== 0 && <Row className="mt-3">
          <h1 className="color-primary pt-5">{t("All PAM Traders")}</h1>
          {/* A header which can change style of master trader cards either by row or by card view */}
          <Row>
            <div className="d-flex justify-content-end">
              <Button
                className="btn btn-primary color-bg-btn border-0 mx-2"
                color="primary"
                onClick={() => {
                  setViewByCard(false);
                }}
              >
                <i
                  className={"bx bx-list-ul bx-sm"}
                ></i>
              </Button>
              <Button
                className="btn btn-primary color-bg-btn border-0 mx-1"
                color="primary"
                onClick={() => {
                  setViewByCard(true);
                }}
              >
                <i
                  className={"bx bx-grid-alt bx-sm"}
                ></i>
              </Button>
            </div>
          </Row>
          {
            viewByCard ? <Row className="mt-2">
              {masterTraders?.filter((item)=> item.profileType === 2).map((masterTrader, key) => (
                <CardView key={key} masterTrader={masterTrader} setProfile={setProfile} />
              ))}
            </Row> : <Row className="mt-2">
              <div className="table-rep-plugin custom-table" style={{ width: "100%" }}>
                <Table
                  responsive className="table table-hover table-borderless text-center"        
                >
                  <Thead >
                    <Tr className="my-5" >
                      <Th></Th>
                      <Th><h3>{t("Username")}</h3></Th>
                      <Th><h3>{t("Profile Type")}</h3></Th>
                      <Th><h3>{t("Investors")}</h3></Th>
                      <Th><h3>{t("Total Investment")}</h3></Th>
                      <Th><h3>{t("Total Profit")}</h3></Th>
                      <Th><h3>{t("Created At")}</h3></Th>
                      <Th><h3>{t("Action")}</h3></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {masterTraders?.filter((item)=> item.profileType === 2).map((masterTrader, key) => (
                      <RowView key={key} masterTrader={masterTrader} setProfile={setProfile} />
                    ))}
                  </Tbody>
                </Table>
              </div>
            </Row>
          }
        </Row>}
        {
          currentModal === "Invest" && profile?._id && 
          <LinkModal isOpen={currentModal === "Invest"} toggle={()=> {
            dispatch(toggleCurrentModal(""));
          }}  profileId={profile?._id} selectedProfile={profile} />
        }
      </Row>
      }

      {!loading && masterTraders?.length !== 0 && <Row >
        {/* <h1 className="color-primary">{t("All Master Traders")}</h1> */}
        {/* A header which can change style of master trader cards either by row or by card view */}
        {/* <Row className="my-3">
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-primary color-bg-btn border-0 mx-2"
              color="primary"
              onClick={() => {
                setViewByCard(false);
              }}
            >
              <i
                className={"bx bx-list-ul bx-sm"}
              ></i>
            </Button>
            <Button
              className="btn btn-primary color-bg-btn border-0 mx-1"
              color="primary"
              onClick={() => {
                setViewByCard(true);
              }}
            >
              <i
                className={"bx bx-grid-alt bx-sm"}
              ></i>
            </Button>
          </div>
        </Row> */}
        {!loading && masterTraders?.length !== 0 && <Row className="mt-3">
          <h1 className="color-primary pt-5">{t("All Copy Traders")}</h1>
          {/* A header which can change style of master trader cards either by row or by card view */}
          <Row>
            <div className="d-flex justify-content-end">
              <Button
                className="btn btn-primary color-bg-btn border-0 mx-2"
                color="primary"
                onClick={() => {
                  setViewByCard(false);
                }}
              >
                <i
                  className={"bx bx-list-ul bx-sm"}
                ></i>
              </Button>
              <Button
                className="btn btn-primary color-bg-btn border-0 mx-1"
                color="primary"
                onClick={() => {
                  setViewByCard(true);
                }}
              >
                <i
                  className={"bx bx-grid-alt bx-sm"}
                ></i>
              </Button>
            </div>
          </Row>
          {
            viewByCard ? <Row className="mt-2">
              {masterTraders?.filter((item)=> item.profileType === 9).map((masterTrader, key) => (
                <CardView key={key} masterTrader={masterTrader} setProfile={setProfile} />
              ))}
            </Row> : <Row className="mt-2">
              <div className="table-rep-plugin custom-table" style={{ width: "100%" }}>
                <Table
                  responsive className="table table-hover table-borderless text-center"        
                >
                  <Thead >
                    <Tr className="my-5" >
                      <Th></Th>
                      <Th><h3>{t("Username")}</h3></Th>
                      <Th><h3>{t("Profile Type")}</h3></Th>
                      <Th><h3>{t("Investors")}</h3></Th>
                      <Th><h3>{t("Total Investment")}</h3></Th>
                      <Th><h3>{t("Total Profit")}</h3></Th>
                      <Th><h3>{t("Created At")}</h3></Th>
                      <Th><h3>{t("Action")}</h3></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {masterTraders?.filter((item)=> item.profileType === 9).map((masterTrader, key) => (
                      <RowView key={key} masterTrader={masterTrader} setProfile={setProfile} />
                    ))}
                  </Tbody>
                </Table>
              </div>
            </Row>
          }
        </Row>}
        {
          currentModal === "Invest" && profile?._id && 
          <LinkModal isOpen={currentModal === "Invest"} toggle={()=> {
            dispatch(toggleCurrentModal(""));
          }}  profileId={profile?._id} selectedProfile={profile} />
        }
      </Row>
      }
      
    </Container>     

  );
};
export default withTranslation()(MasterTraders);
